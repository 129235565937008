.navbar{
    position: fixed;
}
.btn{
margin-top: 5px;
margin-right: 5px;
}
.form-inline label{
width: 70px;
display: inline-block;
}
.form-inline .form-group{
margin-bottom: 5px;
}
.loginError{
color: red;
}
.ajaxLoader{
position: absolute;
left: 50%;
top: 40%;
transform: translate(-50%, -50%);
z-index: 10000;
}
.logoutConfirm{
color: green;
}
.signoutConfirm{
color: red;
margin-bottom: 10px;
}
.modal-content{
padding: 5px;
}
.row{
margin-left: 10px;
}