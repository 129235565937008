.ajaxLoader{
    position: fixed;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);
    z-index: 10000;
  }
body {
    padding-top:153px;
}
.titleIcon{
    position: relative;
    z-index: 10;
}
.titleIcon:after{
    content: '';
    display: block;
    height: 24px;
    width: 24px;
    cursor: pointer;
}
.closeIcon{
    position: relative;
    z-index: 10;
}
.closeIcon:after{
    content: '';
    display: block;
    height: 24px;
    width: 24px;
    cursor: pointer;
}
.nochildIcon{
    position: relative;
    z-index: 10;
}
.nochildIcon:after{
    content: '';
    display: block;
    height: 24px;
    width: 24px;
    cursor: pointer;
}

.alert{
    padding:0 0 0 5px;
    height: 30px;
    margin-bottom: 0;
}

.header{
    overflow: hidden;
    padding-left:30px;
}
.header:hover{
    border: 1px solid black;
    background-color: #dddddd;
    z-index:5;
}
.title{
    cursor: pointer;
}
.content{
    padding-left: 20px;
}
.page > .header{
    border-radius: 15px;
    padding-left: 32px;
    opacity: 0.8;
    z-index: 2;
}
.page{
    position: relative;
}
.page > .titleIcon{
    position: absolute;
    top: 3px;
    left: 5px;
    background:  url(../../icons/arrow-down-circle24.svg) no-repeat;
    border-radius: 15px;
}
.page > .titleIcon:hover{
    background-color: ghostwhite;
}
.page > .closeIcon{
    position: absolute;
    top: 3px;
    left: 5px;
    background:  url(../../icons/arrow-right-circle24-2.svg) no-repeat;
    border-radius: 15px;

}
.page > .closeIcon:hover{
    background-color: ghostwhite;
}
.page > .nochildIcon{
    position: absolute;
    top: 3px;
    left: 5px;
    background:  url(../../icons/arrow-right-circle24-2.svg) no-repeat;
    border-radius: 15px;

    opacity: 0.4;
}
.page > .nochildIcon:hover{
    background-color: ghostwhite;
}
.folder{
    margin-top: 5px;
}
.folder > .alert-primary{
    width: 320px;
}
.folder > .alert-success{
    width: 320px;
}
.folder > .alert-warning{
    width: 320px;
}
.folder > .alert-info{
    width: 320px;
}
.folder > .alert-secondary{
    width: 320px;
}
.folder > .alert-danger{
    width: 320px;
}
.folder > .alert-light{
    width: 320px;
}
.folder > .alert-dark{
    width: 320px;
}
.folder > .header{
    border-radius: 15px;
}
.folder{
    position: relative;
}
.folder > .titleIcon{
    position: absolute;
    top: 3px;
    left: 3px;
    background: url(../../icons/chevron-down24.svg) no-repeat;
    border-radius: 15px;
}
.folder > .titleIcon:hover{
    background-color: ghostwhite;
}
.folder > .closeIcon{
    position: absolute;
    top: 3px;
    left: 3px;
    background: url(../../icons/chevron-right24-3.svg) no-repeat;
    border-radius: 15px;
}
.folder > .closeIcon:hover{
    background-color: ghostwhite;
}
.folder > .nochildIcon{
    position: absolute;
    top: 3px;
    left: 3px;
    background: url(../../icons/chevron-right24-3.svg) no-repeat;
    border-radius: 15px;
    opacity: 0.2;
}
.folder > .nochildIcon:hover{
    background-color: ghostwhite;
}
.book{
    border: 2px solid #999999;
    border-radius: 5px;
    margin-bottom: 5px;
    padding-top: 2px;
    background-color: white;
}
.book > .header{
    font-weight: bold;
    width: 99%;
}
.book{
    position: relative;
}
.book > .titleIcon{
    position: absolute;
    top: 3px;
    left: 0;
    background: #ecd4ff url(../../icons/book-open24.svg) no-repeat;
}
.book > .titleIcon:hover{
    background-color: #ca88ff;
}
.book > .closeIcon{
    position: absolute;
    top: 3px;
    left: 0;
    background: #ecd4ff url(../../icons/book.svg) no-repeat;
    opacity: 1;
}
.book > .closeIcon:hover{
    background-color: #ca88ff;
}
.book > .nochildIcon{
    position: absolute;
    top: 2px;
    left: 0;
    background: #ecd4ff url(../../icons/book.svg) no-repeat;
    opacity: 0.2;
}
.book > .nochildIcon:hover{
    background-color: #ca88ff;
}
.book > .content{
    max-height: 330px;
    overflow-x:visible;
    overflow-y: auto;
    padding-bottom: 5px;
}
#uncontrolled-tab-example-tab-bok{
    position: relative;
    padding-left: 27px;
}
.book-icon{
    position: absolute;
    top: 8px;
    left: 2px;
    background: #ecd4ff url(../../icons/book.svg) no-repeat; 
}
.book-icon:after{
    content: '';
    display: block;
    height: 24px;
    width: 24px;
    cursor: pointer;
    z-index: 1;
}
.link > .header{
    width: 100%;
    background-color: #eeeeee;
    padding-left: 20px;
}
.book  .link > .header{
    width: 100%;
    background-color: transparent;
}
.link > .header:hover{
    background-color: #dddddd;
}
.link{
    position: relative;
}
.link > .titleIcon{
    height: 18px;
    width: 18px;
    position: absolute;
    top: 5px;
    left: 3px;
    background: url(../../icons/star16-1.svg) no-repeat;
}
.link > .titleIcon:hover{
    background-color: #cccccc;
}
.link > .closeIcon{
    height: 18px;
    width: 18px;
    position: absolute;
    top: 5px;
    left: 3px;
    background:  url(../../icons/star16-3.svg) no-repeat;
    /* opacity: 0.4; */
}
.link > .closeIcon:hover{
    background-color: #cccccc;
}
.link > .nochildIcon{
    height: 18px;
    width: 18px;
    position: absolute;
    top: 5px;
    left: 3px;
    background:  url(../../icons/star16-1.svg) no-repeat;
    opacity: 0.4;
}
.link > .nochildIcon:hover{
    background-color: #cccccc;
}
#uncontrolled-tab-example-tab-lnk{
    position: relative;
    padding-left: 20px;
}
.link-icon{
    position: absolute;
    top: 12px;
    left: 2px;
    background: url(../../icons/star16.svg) no-repeat; 
}
.link-icon:after{
    content: '';
    display: block;
    height: 16px;
    width: 16px;
    cursor: pointer;
    z-index: 1;
}
.settings{
    height: 24px;
    width: 24px;
    background: url(../../icons/settings.svg) no-repeat;
    margin-top: 2px;
    opacity: 0;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 0;
    border-radius: 15px;

}
.settings:hover{
    background-color: ghostwhite;
}
.page > .header.public{
    border:2px solid green;
}
.headerTitle{
    margin: auto;
    width: 50%;
    top: -25px;
    position: relative;
    text-align: center;
}
.headerTitle.public{
    color: green;
}
.badge-success.public{
    position: absolute;
    top:-5px;
    left: 0;
}
.page > .header > .settings{
    opacity: 1;
}
.pageList .btn{
    position: relative;
    margin-right: 10px;
    margin-bottom: 5px;
}
.pageList .settings{
    top: 3px;
    border-radius: 10px;
    opacity: 0;
}
.pageList .btn-primary:hover .settings{
    opacity: 1;
}
.header:hover > .settings{
    opacity: 1;
}
.MyModal_public, .MyModal_kind{
    padding-top: 10px;
}
.MyModal_public, .MyModal_kind input{
    margin-right: 5px;
}
.MyModal form{
    margin-top: 5px;
}
.MyModal .error{
    color: red;
}
.quickdelete{
    position: absolute;
    top: 10px;
    right: 50px;
    float: right;
}
.bookmarklet {
    border: 1px solid;
    border-radius: 20px;
  }
/* .navbar {
    padding: 0.5rem 1rem 0.5rem 0.5rem;
} */
.navbar {
    padding: 0 1rem 0.8rem 0.5rem;
}
.Navbar_settings{
    /* float:right; */
    height: 30px;
    width: 30px;
    background: url(../../icons/settings.svg) no-repeat;
    margin-top: 2px;
    opacity: 0.8;
    cursor: pointer;
    position: relative;
    top: 10px;
    border-radius: 15px;
    padding-right:2px;
    padding-bottom:2px;
}
.Navbar_settings:hover{
    opacity: 1;
    background-color: #eeeeee
}
.header:hover > .memoIcon{
    opacity: 1;
}
.memoI, .memo{
    display: block;
    margin-left: 5px;
    background-color: #eeeeee;
    padding-left: 5px;
    opacity: 0.9;
}
.page > .memo, .folder > .memo, .page > .memoI, .folder > .memoI{
    width: 330px;
}
.memoIcon2{
    height: 24px;
    width: 24px;
    background: url(../../icons/message-square24-2.svg) no-repeat;
    margin-top: 2px;
    opacity: 0.5;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 24px;
    background-color: yellow;
}
.memoIcon1{
    height: 24px;
    width: 24px;
    background: url(../../icons/message-square24-2.svg) no-repeat;
    margin-top: 2px;
    opacity: 0.5;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 24px;
}
.memoIcon0{
    height: 24px;
    width: 24px;
    background: url(../../icons/message-square24-1.svg) no-repeat;
    margin-top: 2px;
    opacity: 0.5;
    cursor: pointer;
    position: absolute;
    top: 0;
    right: 24px;
}
.memoIcon0:hover, .memoIcon1:hover{
    background-color: ghostwhite; 
}
.memoShown:after{
    content:'';
    height: 24px;
    width: 24px;
    background: url(../../icons/message-square24-2.svg) no-repeat;
    display: block;
    background-color: yellow;
}
.memoHidden:after{
    content:'';
    height: 24px;
    width: 24px;
    background: url(../../icons/message-square24-1.svg) no-repeat;
    display: block;
}
.tidy1:after{
    content:'';
    height: 24px;
    width: 24px;
    background: url(../../icons/maximize24.svg) no-repeat;
    display: block;
}
.tidy2:after{
    content:'';
    height: 24px;
    width: 24px;
    background: url(../../icons/minimize24.svg) no-repeat;
    display: block;
}
.tidy3:after{
    content:'';
    height: 24px;
    width: 24px;
    background: url(../../icons/minus24.svg) no-repeat;
    display: block;
}
/* search */
#find-box{
    width: 110px;
}
.search:after{
    content:'';
    height: 16px;
    width: 16px;
    background: url(../../icons/search16.svg) no-repeat;
    display: block;
}
.searchBtn{
    margin-top: -4px;
}
.searchReset:after{
    content:'';
    height: 16px;
    width: 16px;
    background: url(../../icons/rotate-cw16.svg) no-repeat;
    display: block;
}
.searchResultTitle .searchReset{
    position: relative;
    top: -20px;
    left:110px;
}
  .title.match{
    border: 1px solid green;
    padding: 0 5px 5px 5px;
  }
  .linkResult{
      position: relative;
  }
  .settingsResult{
    background: #eeeeee url('../../icons/play16.svg') center center no-repeat;
    position: absolute;
    top: 2px;
    left:0;
    z-index:10;
    height: 24px;
  }
  .settingsResult:hover{
    cursor: pointer;
    background-color: #ffffff
  }
  .searchResultTitle{
    display:inline-block;
    border: 1px solid grey;
    border-radius: 5px;
    padding: 2px 2px 2px 5px;
    background-color: #eeeeee;
    width: 140px;
    height: 30px;
  }
 .titleResult a{
    width: 200px;
    height: 30px;
    overflow: hidden;
    position: absolute;
    background-color: #eeeeee;
    left:0;
    padding-left: 20px;
  }
 .titleResult a:hover{
    background-color: #dddddd;
  }
  .hrefResult{
    position: absolute;
    height:24px;
    overflow: hidden;
    background-color: #eeeeee;
    margin-bottom: 5px;
  }

  /* general */
  a:hover{
    text-decoration: none;
  }
  label{
      padding-left: 5px;
  }
  .serverError{
      position: absolute;
      top: 50%;
      left: 50%;
      border: 1px solid red;
      border-radius: 5px;
      padding: 5px;
      color: red;
      z-index: 1000;
      background-color: ghostwhite;
  }
  .serverError .btn{
      margin-left: 5px;
  }
  .btn {
      padding: 0.375rem 0.5rem 0.375rem 0.5rem
  }
  .container.pageList, .container.main, .container.SearchResult{
      padding-top: 5px;
  }
  .book.share{
    border: 2px solid green;
}
  .unshared{
      color: rgb(255, 153, 0);
  }
  .shared{
      color: green;
  }
  .badge-success.share{
    position: absolute;
    top:-15px;
    left: 0;
    z-index:7;
}
/* share */
.share > .py-5{
    padding-top: 0 !important; 
}
.share > h6{
    margin-bottom: 0;
}
.shareMore{
    cursor: pointer;
    color: rgb(24, 24, 53);
}
.shareMore:hover{
    cursor: pointer;
    color: blue;
}
.page.pageSearch{
    padding-bottom: 1vh; 
}
.loadShareMore, .searchShareMore{
    border: 1px solid grey;
    height: 30px;
    background-color: #eeeeee;
    margin: auto;
    width: 300px;
    text-align: center;
}
/* Smartphones (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    /* Styles */

    }
    
    /* Smartphones (landscape) ----------- */
    @media only screen and (min-width : 321px) {
    /* Styles */
    }
    
    /* Smartphones (portrait) ----------- */
    @media only screen and (max-width : 320px) {
    /* Styles */
    }
    @media only screen and (max-width: 480px) {

    }
    /* iPads (portrait and landscape) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    /* Styles */
    }
    
    /* iPads (landscape) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    /* Styles */
    }
    
    /* iPads (portrait) ----------- */
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    /* Styles */
    }

    /**********
    iPad 3
    **********/
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
    }
    
    @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
    }

    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 320px) {
    /* Styles */
    }

    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 480px) {
    /* Styles */
    body {
        padding-top:110px;
    }
    }

    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 600px) {
    /* Styles */

    }

    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 768px) {
    /* Styles */
    body {
        padding-top:67px;
    }
    .container{
        padding:0;
        max-width: 768px;
    }
    .book{
        width: 48%;
        margin-right: 5px;
        display: inline-block;
        vertical-align: top;
    }
    .link{
        width: 100%;
    }
    .page > .content > .link, .folder > .content > .link{
        width: 346px;
        vertical-align: top;
        display: inline-block;
    }
    }

    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 900px) {
    /* Styles */
    }

    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 1024px) {
        /* Styles */
        body {
            padding-top:70px;
        }
        .page{
            padding-bottom: 80vh;
        }
        .pageList{
            position: fixed;
            width: 150px;
            overflow-y: auto;
            top: 100px;
            left:10px;
            bottom: 0;
        }
        .pageList .btn{
            width: 140px;
        }
        .pageList .settings{
            display: block;
        }
        .SearchResult{
            position: fixed;
            width: 180px;
            overflow-y: scroll;
            top: 105px;
            right: 0;
            bottom: 0;
        }
    }
    @media only screen  and (min-width : 1152px) {
        /* Styles */

    }
    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 1200px) {
        /* Styles */
        body {
            padding-top:55px;
        }
        .container{
            max-width: 1100px;
        }
        .book{
            width: 32%;
        }
        .link{
            max-width: 100%;
        }
        .page .content > .link, .folder .content > .link{
            width: 330px;
            margin-right: 5px;
        }
        .pageList{
            width: 160px;
            top:60px;
        }
        .SearchResult{
            top: 65px;
        }
    }

    /* Desktops and laptops ----------- */
    @media only screen  and (min-width : 1224px) {
    /* Styles */
        .container.pageList, .container.main, .container.SearchResult{
            padding-top: 15px;
        }
    }
    
    /* Large screens ----------- */
    @media only screen  and (min-width : 1824px) {
    /* Styles */
    body {
        padding-top:65px;
    }
        .pageList{
            width: 180px;
        }
        .pageList .btn{
            width: 150px;
        }
        .container.pageList, .container.main, .container.SearchResult{
            padding-top: 5px;
        }
    }
    
    /* iPhone 4 ----------- */
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : landscape) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
    }
    
    @media only screen and (min-device-width : 320px) and (max-device-width : 480px) and (orientation : portrait) and (-webkit-min-device-pixel-ratio : 2) {
    /* Styles */
    }
    
    /* iPhone 5 ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-height: 568px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    /* iPhone 6, 7, 8 ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 375px) and (max-device-height: 667px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    /* iPhone 6+, 7+, 8+ ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    /* iPhone X ----------- */
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 375px) and (max-device-height: 812px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    /* iPhone XS Max, XR ----------- */
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 414px) and (max-device-height: 896px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    /* Samsung Galaxy S3 ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 2){
    /* Styles */
    }
    
    /* Samsung Galaxy S4 ----------- */
    @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 320px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    /* Samsung Galaxy S5 ----------- */
    @media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : landscape) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    
    @media only screen and (min-device-width: 360px) and (max-device-height: 640px) and (orientation : portrait) and (-webkit-device-pixel-ratio: 3){
    /* Styles */
    }
    